import React from 'react';
import gr from '../assets/images/Screenshot from 2021-05-07 16-03-23.png';
import '../assets/sass/sassy/greenroom.scss';

function greenroom() {
  return (
    <div className="greenroom">
      <div className="greenroom__background greenroom">
        <div className="greenroom__box">
          <div className="greenroom__background">
            <a href="https://mcruzlehardy.dev">
              <button className="greenroom__button">Back</button>
            </a>

            <h3 className="greenroom__title">GreenRoom</h3>
          </div>

          <div className="greenroom__container">
            <img src={gr} className="greenroom__img" alt="Landing Page" />
            <div>
              <div className="greenroom__babyBlue">
                <h3 className="greenroom__smallTitle">Status:</h3>
                <h5 className="greenroom__smallTitle">In progress</h5>
              </div>
              {/* <div className="greenroom__darkBlue">
                <h3 className="greenroom__smallTitle">Url</h3>
                <p className="greenroom__smallTitle">Coming soon!</p>
              </div> */}
              <div className="greenroom__techStack">
                <h3 className="greenroom__smallTitle">Tech Stack</h3>
                <p className="greenroom__smallTitle">
                  React.js, Node.js, Firebase, SASS, React hooks, Bootstrap,
                  Context, Calendly Integration, Yelp-Fusion, Git
                </p>
              </div>
              {/* <div className="greenroom__darkBlue--tablet">
                <h3 className="greenroom__smallTitle">Url</h3>
                <p className="greenroom__smallTitle">Coming soon!</p>
              </div> */}
            </div>
          </div>
          <div className="greenroom__teal">
            <h3 className="greenroom__smallTitle--desc">Description</h3>
            <p className="greenroom__smallTitle">
              GreenRoom is a web app for musicians to scope out the local scene
              and connect with other musicians.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default greenroom;
